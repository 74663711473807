@import "../../../../../../lib/uio1/profile/css/util.less";

/* latin */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/worksans-normal.woff2");
}
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/worksans-bold.woff2");
}

#hidnav a[href="#bottomnav"] {
  display: none;
}

:focus {
  outline: -webkit-focus-ring-color auto 5px;
}
// External links

#vrtx-content,
#vrtx-related-content {
  a[href*="//"]:not([href*="nalokson.uio.no"]):not(button):not(.img-link) {
    &:after {
      content: "";
      vertical-align: baseline;
      margin-left: 3px;
      margin-right: 5px;
      display: inline-block;
      width: 12px;
      height: 12px;
      background-image: none, url("../images/ekstern-lenke.svg");
      background-repeat: no-repeat;
    }
  }
}

p {
  margin-bottom: 30px;

  a,
  a:focus,
  a:hover {
    text-decoration: underline;
  }
}

.vrtx-toc-header,
caption,
h1,
h2,
h3 {
  font-weight: 600;
}

.vrtx-toc-header,
h2 {
  .font-size(26);
}

h3 {
  .font-size(22);
  line-height: normal;
}

body {
  .font-size(18);
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  color: @color-dark;
  font-family: @font-sans-serif;
  // avoiding scrollbar when wide boxes are used eg. authors-wrapper and kilde-wrapper
  overflow-x: hidden;
}

.introduction-div,
.vrtx-frontpage-box.introduction .vrtx-box-content,
.vrtx-introduction,
.vrtx-introduction-big,
.vrtx-organizational-unit-introduction {
  .font-size(18);
  line-height: 1.5;
  font-family: @font-sans-serif;
}

.vrtx-frontpage-full-width.total-main #main #vrtx-change-language-link,
.vrtx-frontpage-full-width.total-main #main #vrtx-frontpage-introduction,
.vrtx-frontpage-full-width.total-main #main .row,
.vrtx-frontpage-full-width.total-main #main .vrtx-back,
.vrtx-frontpage-full-width.total-main #main .vrtx-context-box,
.vrtx-frontpage-full-width.total-main #main h1 {
  width: 680px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.not-for-ansatte {
  #main .button:not(.comment-delete-button):not(#vrtx-comments-delete-all):not(.red):not(.program-create-print):not(.program-create-send-dialog):not(.vrtx-program-read-more):not(.vrtx-program-how-search):not([class^="admin-frontpage"]):not([class^="admin-box-row"]):not([class^="vrtx-frontpage-box"]):not([class^="change-positions-in-row"]):not([class^="vrtx-edit-"]):not(.undo):not(.x-cancel),
  #main button:not(.comment-delete-button):not(#vrtx-commments-delete-all):not(.red):not(#fixed-btn):not([class^="admin-frontpage"]):not([class^="admin-box-row"]):not([class^="vrtx-frontpage-box"]):not([class^="change-positions-in-row"]):not([class^="vrtx-edit-"]):not(.undo):not(.x-cancel),
  #main input[type="button"]:not(.red),
  #main input[type="cancel"]:not(.red),
  #main input[type="submit"]:not(.submit-comment-button):not(.red) {
    font-family: @font-sans-serif;
    color: @color-primary;
    background-color: @color-light;
    -webkit-border-radius: 22px;
    -moz-border-radius: 22px;
    border-radius: 22px;
    border: 1px @color-primary solid;
    font-weight: 400;
    .font-size(18);

    &:focus,
    &:hover {
      border-color: @color-primary;
      background-color: @color-primary;
      color: @color-light;
      box-shadow: 0 0 10px 0 @color-light;
    }

    a:focus,
    a:hover {
      text-decoration: underline;
    }
  }
}

#main form.vrtx-big-search {
  input[type="search"] {
    -webkit-border-radius: 0 22px 22px 0;
    -moz-border-radius: 0 22px 22px 0;
    border-radius: 0 22px 22px 0;
    border: 1px solid @color-primary;
  }

  button[type="submit"] {
    height: 52px;

    &:after,
    &:before {
      display: none;
    }
  }
}
// to-top button

#main button#fixed-btn {
  background: transparent url("../images/to-top-button.svg") no-repeat center !important;
  display: none;
  cursor: pointer;
  position: fixed;
  top: 20px;
  right: 20px;
  color: transparent;
  border: none;
  height: 50px;
  width: 50px;
  margin: 0;
  padding: 0;

  &:focus,
  &:hover {
    background: transparent url("../images/to-top-button-hover.svg") no-repeat center !important;
  }
}

.not-for-ansatte {
  .header-search-collapsable .header-search-expand {
    background-color: @color-dark;
    color: @color-light;
  }

  #head-wrapper {
    #head {
      height: 166px;

      #header {
        height: 100%;

        .logo {
          height: 100%;
          position: absolute;
          width: 450px;

          a {
            position: absolute;
            top: 10px;
            width: 124px;
            height: 100%;
            .font-size(50);
            color: @color-light;
            display: block;
            padding: 10px 0;
            top: 0;
            position: absolute;

            img {
              margin-top: 35px;
              width: 104px;
              margin-left: 20px;
              height: 79px;
              z-index: 10;
            }

            .logo-text {
              position: absolute;
              top: 40px;
              left: 145px;
            }
          }

          .byline {
            color: @color-light;
            position: absolute;
            top: 100px;
            left: 145px;
            .font-size(18);
          }
        }
      }
    }
    background-color: @color-primary;

    .header-search {
      top: 18px;
      z-index: 10;

      input[type="text"] {
        background: @color-light;
        border-radius: 22px 0 0 22px;
        .placeholderColor(@color-dark, @color-dark);
        color: @color-neutral--dark;
        margin: 0 -6px 0 0;
      }

      button:not(#fixed-btn):not([class^="admin-frontpage"]):not([class^="admin-box-row"]):not([class^="vrtx-frontpage-box"]):not([class^="change-positions-in-row"]):not([class^="vrtx-edit-"]):not(.undo):not(.x-cancel) {
        background: @color-primary;
        color: @color-light;
        font-weight: 400;
        vertical-align: bottom;
        border: 1px solid @color-light;
        border-radius: 0 22px 22px 0;
        height: 40px;
      }

      .header-search-expand {
        padding: 7px 15px 7px 34px;
        background: url("/vrtx/dist/resources/uio1/profile/images/responsive/search-icon.svg") 9px 9px no-repeat @color-light;
        background-size: 20px 20px;
        border-radius: 22px;
        color: @color-primary;

        &:focus,
        &:hover {
          background: url("/vrtx/dist/resources/uio1/profile/images/responsive/search-icon-active.svg") 9px 9px no-repeat;
          background-size: 20px 20px;
          color: @color-light;
        }
      }
    }

    .vrtx-frontpage-box-picture img {
      margin-top: -36px;
    }

    .uio-app-name {
      display: none;
    }

    .head-menu {
      right: 120px;

      .vrtx-login-manage-component > a,
      ul > li a {
        color: @color-light;
      }
    }

    .vrtx-dropdown-component .vrtx-dropdown-wrapper ul li a {
      color: @color-dark;
    }
  }
}

#globalnav {
  background: @color-primary--dark;
  padding: 5px 0;

  ul {
    display: flex;
    justify-content: space-between;

    li.vrtx-active-item a {
      background: @color-secondary;
      color: @color-primary--dark;
      border-radius: 27px;
      box-shadow: 0 0 10px 0;
    }

    li {
      margin: 0 10px 0 0;

      a {
        padding: 8px 15px 10px;
      }

      &.vrtx-active-item a,
      a {
        &:focus,
        &:hover {
          background: @color-primary;
          color: @color-light;
          border-radius: 27px;
          box-shadow: 0 4px 6px -2px @color-primary;
        }
      }
    }
  }
}

#left-main {
  .vrtx-breadcrumb-menu {
    li > a.vrtx-marked,
    li > span.vrtx-marked,
    li.vrtx-child > a.vrtx-marked,
    li.vrtx-parent > a.vrtx-marked {
      background: @color-primary;
      color: @color-light;
    }

    li > a.vrtx-marked,
    li > span.vrtx-marked,
    li.vrtx-child > a,
    li.vrtx-child > a.vrtx-after-marked,
    li.vrtx-child > a.vrtx-marked,
    li.vrtx-child > span {
      border-top: none;
    }

    li > a,
    li > span {
      padding: 12px 15px;
      background: @color-neutral--air;
      font-family: @font-sans-serif;
      color: @color-primary;
    }

    li.vrtx-parent {
      a {
        margin-bottom: 20px;
        height: 45px;
        border-radius: 35px;
      }

      a:after {
        position: relative;
        left: 64px;
        bottom: -10px;
        display: block;
        content: " ";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 12px 15px 0;
        border-color: @color-neutral--air transparent transparent;
      }

      a::before,
      span::before {
        content: " " !important;
      }

      > a.vrtx-marked:after {
        border-color: @color-primary transparent transparent;
      }
    }

    li.vrtx-child {
      a {
        margin-bottom: 10px;
        padding-left: 20px;
        border-radius: 35px;
      }

      a::before,
      span::before {
        content: " " !important;
      }

      > a.vrtx-marked:after,
      a::after {
        display: none;
      }
    }

    li.vrtx-ancestor {
      > a,
      > span {
        border-radius: 35px;

        &:after {
          border-color: @color-neutral--air transparent transparent;
          bottom: -12px;
        }
      }
    }
  }
}

#vrtx-frontpage #main .grid-container.full-width-picture-bg {
  background-position: top right !important;
}

#main .vrtx-back a:before {
  background: url("../images/icon-chevron-back.svg") no-repeat 0 3px transparent;
  background-size: auto 80%;
}

#vrtx-frontpage .vrtx-frontpage-box.heading3-with-image.vrtx-frontpage-box-picture,
.vrtx-frontpage-box.heading2-with-image.vrtx-frontpage-box-picture {
  h3 {
    margin-bottom: 35px;
    padding-right: 0;
    padding-top: 20px;
  }

  h2 {
    margin-bottom: 35px;
    padding-right: 0;
    padding-top: 20px;
  }

  .vrtx-frontpage-box-picture {
    float: right;
    margin: -10px 0 0;
    max-width: 100px;
  }
}
// Custom radio buttons

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 40px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: @color-dark;
  //font-family: @font-header;
  &:focus,
  &:hover {
    color: @color-primary--dark;
  }
}

[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: -3px;
    width: 18px;
    height: 18px;
    border: 2px solid @color-primary;
    border-radius: 100%;
    background: @color-light;
  }

  &:focus:before,
  &:hover:before {
    border: 2px solid @color-primary--dark;
  }
}

[type="radio"]:checked + label:before {
  border: 2px solid @color-primary--dark;
}

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: @color-primary--dark;
  position: absolute;
  top: 2px;
  left: 5px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

#main #vrtx-content .vrtx-box-content {
  padding-bottom: 40px;
}

.footer > .vrtx-search-scope a::before,
a.all-comments::before,
a.all-messages::before,
a.more-url::before,
a.vrtx-more::before,
div.vrtx-more a::before {
  background: url("../images/arrow-forward.svg") no-repeat 0 10px;
}

.ui-accordion.ui-widget {
  .ui-accordion-header {
    padding: 10px 0 30px 30px;

    .ui-icon {
      background: transparent url("../images/icon-chevron-right.svg") no-repeat center left;
    }
  }

  .ui-accordion-header.ui-accordion-header-active .ui-icon {
    background-image: url("../images/icon-chevron-down.svg");
  }

  .helsepersonell,
  .pa-medisiner,
  .parorende,
  .professor,
  .rusavhengig {
    &.ui-accordion-header .ui-icon {
      width: 75px;
      height: 80px;
      margin-bottom: -36px;
      margin-right: 15px;
    }
  }

  .parorende.ui-accordion-header .ui-icon {
    background: transparent url("/bilder/parorende.png") no-repeat center left;
    background-size: 100%;
  }

  .pa-medisiner.ui-accordion-header .ui-icon {
    background: transparent url("/bilder/pa-medisiner.png") no-repeat center left;
    background-size: 100%;
  }

  .professor.ui-accordion-header .ui-icon {
    background: transparent url("/bilder/professor.png") no-repeat center left;
    background-size: 100%;
  }

  .helsepersonell.ui-accordion-header .ui-icon {
    background: transparent url("/bilder/helseperson.png") no-repeat center left;
    background-size: 100%;
  }

  .rusavhengig.ui-accordion-header .ui-icon {
    background: transparent url("/bilder/rusavhengig.png") no-repeat center left;
    background-size: 100%;
  }
}

.play-GIF-on-hover {
  width: 100px;
  height: 94px;
  background-image: url("/bilder/pupiller.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  float: right;
}

.play-GIF-on-hover:hover {
  background-image: url("/bilder/pupiller2.gif");
  animation-iteration-count: 2;
}

#main .button:not(.study-select):not(.edit-link):not(.program-create-print):not(.program-create-send-dialog):not(.white) {
  background-image: url("../images/button-arrow.svg");
  background-position: 95% 50%;
  background-size: auto 0.8em;
  padding-right: 80px;

  &:focus,
  &:hover {
    background-image: url("../images/button-arrow-hover.svg");
  }
}

table {
  th {
    font-weight: bold;
  }
}

hr {
  border-bottom: 4px solid @color-neutral--air;
  &.pre-results {
    display: block;
  }
}

.toc-hide-h3 #toc ul li ul {
  display: none;
}

div#utlevering {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .valgt-filter {
    width: 100%;
  }

  .nalokson-heading {
    width: 100%;
    margin-top: 30px;
    border-top: 4px solid @color-neutral--air;
  }

  .nalokson-item {
    width: 45%;
  }
}

#sticky-bottom-wrapper {
  //display: none;
  position: -webkit-sticky;
  position: sticky;
  background-color: @color-neutral--air;
  margin-top: 30px;
  margin-bottom: -32px;
  padding-bottom: 1px;
  bottom: 0;
  min-width: 100%;
  //outline: 15px solid @color-neutral--air;
  width: calc(100vw);
  margin-left: 50%;
  transform: translateX(-50%);

  #sticky-bottom {
    width: 970px;
    margin: 0 auto;

    p.mer-om {
      display: none;
      .font-size(16);
      margin: 0 0 0 -15px;
      padding: 0 0 10px 45px;
      background-color: @color-neutral--air;
    }

    h2.mer-om {
      margin: 0 0 0 -15px;
      padding: 0 0 0 15px;
      background-color: @color-neutral--air;
      cursor: default;
      .font-size(18);
      font-family: @font-sans-serif;
      color: @color-dark;

      &:focus {
        outline: none;
      }

      &::before {
        content: " ";
        background: url("../images/icon-chevron-right.svg") no-repeat;
        background-size: 30%;
        height: 15px;
        width: 30px;
        background-position-y: center;
        display: inline-block;
      }

      &.active {
        cursor: default;

        &::before {
          content: " ";
          background-size: 50%;
          background-image: url("../images/icon-chevron-down.svg");
        }
      }

      &:focus,
      &:hover {
        text-decoration: underline;
        color: @color-dark;
      }
      &:focus {
        outline: -webkit-focus-ring-color auto 1px;
      }
    }
  }
}

.uio-main ul > li:before,
:not(#footer-wrapper).grid-container ul > li:before {
  content: "\25CF";
  color: @color-primary;
  margin: 7px;
  position: relative;
}

ul.social-follow {
  margin-left: -14px !important;

  > li {
    &::before {
      content: "";
    }
  }
}
/* Footer */
#bottomnav {
  display: none !important;
}

#footer-wrapper {
  color: @color-light;
  background: @color-primary !important;

  &.grid-container ul > li:before {
    content: "";
  }

  #footers {
    ul.footer-links li {
      margin: 0;
    }

    .social-components {
      a {
        color: transparent;
        margin-right: 10px;
        padding: 45px 0;
        width: 48px;
        height: 48px;
        display: inline-block;

        &:focus,
        &:hover {
          color: transparent;
          box-shadow: none;
        }

        &.facebook {
          background: transparent url("../images/social-facebook.svg") no-repeat scroll left center;
          background-size: 100% auto;

          &:focus,
          &:hover {
            background: transparent url("../images/social-facebook-hover.svg") no-repeat scroll left center;
            background-size: 100% auto;
            background-color: transparent;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            color: transparent;
          }
        }
      }
    }
    .font-size(18);
    color: @color-light;

    a,
    h2 {
      color: @color-light;
    }

    p {
      margin-top: 15px;
    }

    .col-2-5.responsible-login {
      span.menu-label {
        display: none;
      }

      .content {
        margin-right: 0;
        margin-left: 100px;
      }
    }

    .vrtx-dropdown-component {
      .font-size(16);
    }
  }

  &.red {
    background: @color-dark none;
  }
}
@media print {
  .not-for-ansatte #head-wrapper #head {
    height: 100px !important;

    #header {
      background: none;

      .logo {
        a {
          background: none;

          img {
            margin-top: 0;
          }

          .logo-text {
            color: @color-dark;
            top: 4px;
          }
        }

        .byline {
          color: @color-dark;
          top: 49px;
        }
      }
    }
  }
}
@media only screen and (max-width: 16cm) and (orientation: portrait), only screen and (max-width: 19cm) and (orientation: landscape) {
  h1,
  h2,
  h3,
  h4 {
    font-weight: 600;
  }

  h1 {
    .font-size(28);
    line-height: 3rem;
  }

  .vrtx-toc-header,
  h2 {
    .font-size(24);
    line-height: 3rem;
  }

  h3 {
    .font-size(20);
  }

  h4 {
    .font-size(18);
  }

  body {
    .font-size(16);
    line-height: 1.5;
  }

  .js-ready #nav-offcanvas {
    background: @color-primary;

    #lang-link {
      border: 1px solid @color-primary--dark;
      background: @color-secondary;
      border-radius: 10px;

      a {
        color: @color-primary--dark;
      }
    }

    li {
      border-top: 1px solid @color-secondary;
      background: @color-primary--dark;

      &.is-active > a {
        background-color: @color-secondary !important;
        color: @color-dark;
      }

      &.ancestor li {
        background-color: @color-primary--dark;
      }

      a#close-nav {
        color: @color-light;
      }

      &.vrtx-parent li.vrtx-child:before {
        top: 0.9em;
        color: @color-secondary;
      }
    }

    .nav-seperator {
      background-color: @color-secondary;
    }
  }

  .not-for-ansatte {
    #head-wrapper #head {
      height: 90px;

      #header {
        .logo {
          width: 100%;

          a {
            width: 100%;

            img {
              margin-top: 0;
              width: 75px;
              margin-left: 15px;
              height: 47px;
            }

            .logo-text {
              position: absolute;
              top: 10px;
              left: 112px;
              .font-size(36);
              width: 100%;
            }
          }

          .byline {
            position: absolute;
            width: 100%;
            top: 62px;
            left: 100px;
            margin: 0 auto;
            .font-size(12);
            line-height: 1;
          }
        }
      }
    }

    #menu-wrapper {
      .menu,
      .menu li.active {
        background: @color-primary;

        li a.toggle-search {
          float: right;
          margin-right: 20px;
        }
      }
    }

    .menu-search button:not(#fixed-btn) {
      border-radius: 0 22px 22px 0;
      height: 45px;
      background: @color-secondary;

      &:hover {
        background: @color-primary;
      }

      &:after {
        left: -14px;
        width: 14px;
        height: 43px;
        border: 0;
        border-width: 0;
        border-radius: 0;
        -moz-border-radius: 0;
        -webkit-border-radius: 0;
        border-top-left-radius: 0;
        -webkit-border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        -webkit-border-bottom-left-radius: 0;
        -moz-border-radius-topleft: 0;
        -moz-border-radius-bottomleft: 0;
      }
    }
  }

  #main {
    #right-main,
    #total-main {
      .vrtx-frontpage-box.vrtx-frontpage-box-picture .vrtx-frontpage-box-picture {
        margin-right: 0;
        margin-left: 0;
        margin-top: 10px;
        float: right;
      }
    }
  }

  div#utlevering {
    .nalokson-item {
      width: 100%;
    }
  }

  #sticky-bottom-wrapper {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    display: block;

    #sticky-bottom {
      width: 100%;
      padding-left: 15px;

      h2.mer-om {
        .font-size(16);
      }

      p.mer-om {
        .font-size(14);
      }
    }
  }

  .not-for-ansatte {
    p.image {
      text-align: center;
    }

    #footer-wrapper #footers {
      margin-bottom: 40px;

      ul.footer-links li {
        margin: 0 9px 5px 10px;
      }

      .col-2-5,
      .col-3-5 {
        width: 100%;
      }

      .content {
        margin-left: 10px;
      }
    }
  }
}
/*------------------------------------*\
# colour
\*------------------------------------*/
// Primary, secondary etc...
@color-primary: #334282; //dark blue
@color-primary--dark: #262C66; //darker blue
@color-secondary: #c8cddd; //greyblue
//@color-contrast: #ad061b; //burgunder (ring113-knapp)
@color-neutral--dark: #4f4f4f; //mørk grå
@color-neutral--air: #ECEEF5; //lys blågra
// Suplementary colors
@color-light: #ffffff;
@color-dark: #2b2b2b; //Mork gra
/*------------------------------------*\
# fonts
\*------------------------------------*/
@font-sans-serif: "Work Sans", sans-serif;
